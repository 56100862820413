<template>
  <div>
    <v-img class="rounded-image" :src="flagFile" :width="size" :height="size" :alt="country" />
  </div>
</template>

<script>
export default {
  name: 'RoundedFlag',
  props: {
    country: {
      type: [String],
      default: '',
    },
    size: {
      type: String,
      default: '50px',
    },
  },
  computed: {
    flagFile: function () {
      if (this.country === 'rest')
        return require(`@/assets/images/flags/globe_america_detailed.svg`);
      else if (this.country === 'all')
        return require(`@/assets/images/flags/globe_america_detailed.svg`);

      if (this.country) return require(`@/assets/images/flags/${this.country.toLowerCase()}.svg`);
      return '';
    },
  },
};
</script>

<style>
.rounded-image {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: 1px solid rgb(133, 133, 133);
}
</style>
