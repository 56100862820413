<template>
  <div>
    <template v-if="loading">
      <loading :text="$t('textTemplate.loading')" />
    </template>
    <template v-else>
      <v-container full-height fluid>
        <v-row>
          <v-col cols="12" md="8" lg="8">
            <v-card class="pa-4">
              <v-row class="px-6 py-2 mb-2" align="center">
                <v-btn icon color="primary" class="mr-4" aria-label="back" @click="navigateBack">
                  <v-icon large>fal fa-arrow-circle-left</v-icon>
                </v-btn>
                <v-card-title v-if="!templateId" class="pl-2">{{
                  $t('textTemplate.createNew')
                }}</v-card-title>
                <v-card-title v-else class="pl-2">{{
                  $t('textTemplate.editTemplate')
                }}</v-card-title>
                <v-spacer />
                <v-btn
                  v-if="!templateId"
                  class="my-4"
                  rounded
                  color="primary"
                  :loading="saving"
                  :disabled="saveDisabled"
                  @click="saveTemplate"
                  >{{ $t('save') }}</v-btn
                >
                <template v-else>
                  <v-btn
                    class="my-4 mr-2"
                    rounded
                    dark
                    color="third"
                    :loading="saving"
                    @click="deleteTemplate"
                    >{{ $t('delete') }}</v-btn
                  >
                  <v-btn
                    class="my-4"
                    rounded
                    color="primary"
                    :loading="saving"
                    :disabled="saveDisabled"
                    @click="saveTemplate"
                    >{{ $t('update') }}</v-btn
                  >
                </template>
              </v-row>
              <template v-if="templateId && existingTemplate">
                <div class="user-info mx-3 mb-2 pa-2">
                  <p class="mb-2">
                    {{ $t('textTemplate.createdBy') }}
                    {{ existingTemplate.createdByUsername }}
                    {{ $d(new Date(existingTemplate.created), 'long') }}
                  </p>
                  <template v-if="existingTemplate.updatedByUsername && existingTemplate.updated">
                    <p>
                      {{ $t('textTemplate.updatedBy') }}
                      {{ existingTemplate.updatedByUsername }}
                      {{ $d(new Date(existingTemplate.updated), 'long') }}
                    </p>
                  </template>
                </div>
              </template>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  dense
                  :label="$t('name')"
                  :rules="namerules"
                  outlined
                  required
                  persistent-hint
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="defaultMessage"
                  outlined
                  auto-grow
                  autofocus
                  rows="2"
                  row-height="30"
                  :label="$t('textTemplate.message')"
                  color="accent"
                  hide-details="auto"
                  :error="!hasValidDefaultMessage"
                />
                <p
                  class="mb-0 mt-1 mr-1 caption text-right"
                  :class="!hasValidDefaultMessage ? 'error--text' : ''"
                >
                  {{ characterCountText }}
                </p>
                <v-alert v-if="exceededCharactersCount" type="error" dense>
                  {{
                    $t('textTemplate.messageTooLong', {
                      maxLength: defaultMessageMaxLength,
                    })
                  }}
                </v-alert>
                <v-alert v-if="defaultMessageEncoding === 'ucs-2'" dense text type="warning">
                  {{ $t('textTemplate.messageContainsSpecialCharacter') }}
                </v-alert>
              </v-col>

              <v-col cols="12">
                <div
                  v-for="msg in countryMessages"
                  :key="msg.country.code"
                  class="country-msg-card"
                >
                  <country-message-input
                    :item="msg"
                    @removeCountryMessage="removeMessage"
                    @validatedMessage="validatedMessage"
                  />
                </div>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="4">
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="fal fa-search"
                  :label="$t('textTemplate.search')"
                  single-line
                  hide-details
                  clearable
                />
              </v-card-title>
              <v-data-table
                :headers="countryListHeaders"
                :items="countries"
                class="elevation-1"
                :items-per-page="15"
                :search="search"
                :no-data-text="$t('textTemplate.noCountriesFound')"
                :no-results-text="$t('textTemplate.noResults')"
                :footer-props="{
                  itemsPerPageText: $t('rowCount'),
                  pageText: '{0}-{1} ' + $t('of') + ' {2}',
                  itemsPerPageAllText: $t('all'),
                }"
              >
                <template #[`item.name`]="{ item }">
                  <div v-if="item" class="d-flex align-center">
                    <rounded-flag :country="item.code" size="25px" />
                    <span class="ml-4">{{ item.name }}</span>
                  </div>
                </template>
                <template #[`item.actions`]="{ item }">
                  <div v-if="countryMessageExists(item.code)">
                    <v-icon color="green"> fal fa-clipboard-check </v-icon>
                  </div>
                  <div v-else class="selectable" @click="openNewCountryMessage(item)">
                    <v-icon color="primary"> fa fa-plus-circle </v-icon>
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="snackbar" color="success" outlined top timeout="2000">
        <v-icon left color="success">fas fa-check-circle</v-icon>
        {{ snackbarText }}
      </v-snackbar>
      <Confirm ref="confirm" />
    </template>
  </div>
</template>
<script>
import RoundedFlag from '@/components/RoundedFlag.vue';
import { textIsUcs2, extendedCharactersCount } from '@/util/messageHelper';
import CountryMessageInput from '@/components/CountryMessageInput.vue';
import Loading from '@/components/Loading.vue';
import Confirm from '@/components/dialog/Confirm.vue';
import { maxLength, minLength, required } from '@/util/formRules';
export default {
  name: 'TextTemplate',

  components: {
    RoundedFlag,
    CountryMessageInput,
    Loading,
    Confirm,
  },
  data: () => ({
    loading: true,
    saving: false,
    search: '',
    snackbar: false,
    snackbarText: '',
    name: '',
    defaultMessage: '',
    defaultMessageMaxLength: 0,
    defaultMessageErrorState: false,
    defaultMessageErrorMsg: '',
    defaultMessageSmsCount: 0,
    defaultMessageTextLength: 0,
    defaultMessageEncoding: 'gsm-7',

    countryMessages: [],
    hasInvalidCountryMessage: false,
  }),
  computed: {
    namerules() {
      const field = this.$t('name');
      return [required(field), minLength(field, 3), maxLength(field, 128)];
    },
    countryListHeaders() {
      return [
        { text: '', value: 'actions', sortable: false, width: '20px' },
        { text: this.$t('textTemplate.country'), value: 'name' },
        // { text: 'Code', value: 'code', align:'right', sortable: false }
      ];
    },
    templateId() {
      return this.$route.params['id'];
    },
    existingTemplate() {
      return this.$store.state.textTemplate.template;
    },
    countries() {
      const countries = this.$store.state.textTemplate.countries;
      const indexNorway = countries.findIndex((country) => country.code == 'NO');

      if (indexNorway > 0) {
        // Create shallow copy of array with new order
        return [].concat(
          countries[indexNorway],
          countries.slice(0, indexNorway),
          countries.slice(indexNorway + 1),
        );
      }

      return countries;
    },
    hasValidDefaultMessage() {
      if (
        this.defaultMessageTextLength >= 3 &&
        this.defaultMessageTextLength <= this.defaultMessageMaxLength
      ) {
        return true;
      } else {
        return false;
      }
    },
    exceededCharactersCount() {
      if (this.defaultMessageTextLength > this.defaultMessageMaxLength) {
        return true;
      } else {
        return false;
      }
    },
    characterCountText() {
      return (
        this.$t('characters') +
        this.defaultMessageTextLength +
        ' / ' +
        this.defaultMessageMaxLength +
        ' (' +
        this.defaultMessageSmsCount +
        ' SMS)'
      );
    },
    saveDisabled() {
      if (this.saving) {
        return true;
      } else if (this.name.length < 3 || this.name.length > 128) {
        return true;
      } else if (!this.hasValidDefaultMessage) {
        return true;
      } else if (this.countryMessages.length > 0 && this.hasInvalidCountryMessage) {
        return true;
      } else return false;
    },
  },
  watch: {
    defaultMessage() {
      this.remainingCharactersCount();
      this.defaultMessageErrorState = false;
      this.defaultMessageErrorMsg = '';
    },
    existingTemplate() {
      if (this.existingTemplate) {
        (this.name = this.existingTemplate.name),
          (this.defaultMessage = this.existingTemplate.defaultMessage);
        if (this.existingTemplate.countryMessages) {
          this.existingTemplate.countryMessages.forEach((msg) => {
            let currentCountry = this.countries.find((c) => c.code == msg.countryCode);
            let countryMessage = {
              message: msg.content,
              country: { code: msg.countryCode, name: msg.countryCode },
              encoding: msg.encoding,
            };
            if (currentCountry)
              countryMessage = {
                message: msg.content,
                country: { code: msg.countryCode, name: currentCountry.name },
                encoding: msg.encoding,
              };
            this.countryMessages.push(countryMessage);
          });
        }
      }
    },
    templateId() {
      if (this.templateId) {
        this.getTextTemplate(this.templateId);
      } else {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getCountries();
    if (this.templateId) {
      this.getTextTemplate(this.templateId);
    } else {
      this.loading = false;
    }
  },
  methods: {
    validatedMessage() {
      let hasInvalidCountry = false;
      for (let i = 0; i < this.countryMessages.length; i++) {
        const parsedCountryMessage = JSON.parse(JSON.stringify(this.countryMessages[i]));
        if (parsedCountryMessage.isValid === false) {
          hasInvalidCountry = true;
          break;
        }
      }
      this.hasInvalidCountryMessage = hasInvalidCountry;
    },
    async getTextTemplate(id) {
      this.loading = true;
      return this.$store
        .dispatch('textTemplate/getTextTemplate', id)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          alert(error);
          this.loading = false;
        });
    },
    async getCountries() {
      try {
        await this.$store.dispatch('textTemplate/getCountries');
      } catch (error) {
        if (!error.hasBeenHandled) {
          alert(error);
        }
      }
    },
    remainingCharactersCount() {
      if (!this.defaultMessage || this.defaultMessage.length === 0) {
        this.defaultMessageTextLength = 0;
        this.defaultMessageEncoding = 'gsm-7';
        this.defaultMessageMaxLength = 960 - 6 * 7;
        return;
      }
      let isUcs2 = false;
      if (this.defaultMessage.length > 0) {
        isUcs2 = textIsUcs2(this.defaultMessage);
      }

      this.defaultMessageTextLength = this.defaultMessage.length;
      if (isUcs2) {
        this.defaultMessageEncoding = 'ucs-2';
        this.defaultMessageMaxLength = 420 - 6 * 3;
      } else {
        this.defaultMessageEncoding = 'gsm-7';
        this.defaultMessageMaxLength = 960 - 6 * 7;
        let extCount = extendedCharactersCount(this.defaultMessage);
        this.defaultMessageTextLength = extCount + this.defaultMessage.length;
      }

      this.defaultMessageSmsCount = this.numberOfDefaultMessageSMS(this.defaultMessageEncoding);
    },
    numberOfDefaultMessageSMS(encoding) {
      let maxSingleSmsLength = 160;
      let internalLength = 153;
      if (encoding == 'ucs-2') {
        maxSingleSmsLength = 70;
        internalLength = 67;
      }
      let numberOfSms = 0;
      if (!this.defaultMessage || this.defaultMessageTextLength == 0) {
        return 0;
      } else if (this.defaultMessageTextLength <= maxSingleSmsLength) {
        numberOfSms = 1;
      } else {
        numberOfSms = this.defaultMessageTextLength / internalLength;
      }
      return Math.ceil(numberOfSms);
    },
    countryMessageExists(countryCode) {
      if (this.countryMessages.some((data) => data.country.code === countryCode)) return true;
      else return false;
    },
    openNewCountryMessage(item) {
      let countryMessage = {
        message: '',
        country: { code: item.code, name: item.name },
        encoding: 'gsm-7',
        isValid: false,
      };
      this.countryMessages.unshift(countryMessage);
    },
    removeMessage(msgToRemove) {
      const existingMessage = this.countryMessages.find(
        (c) => c.country.code == msgToRemove.country.code,
      );
      if (existingMessage) {
        this.countryMessages = this.countryMessages.filter((item) => item !== existingMessage);
      }
    },
    async saveTemplate() {
      this.saving = true;
      let messages = [];
      // convert countrymessages to server-accepted object
      if (this.countryMessages.length > 0) {
        this.countryMessages.forEach((msg) => {
          let countryMessage = {
            content: msg.message,
            encoding: msg.encoding,
            countryCode: msg.country.code,
          };
          messages.push(countryMessage);
        });
      }
      if (this.templateId) {
        // Update existing template
        this.snackbarText = this.$t('textTemplate.updateOk');
        await this.$store.dispatch('textTemplate/updateTextTemplate', {
          id: this.templateId,
          name: this.name,
          defaultMessage: this.defaultMessage,
          defaultMessageEncoding: this.defaultMessageEncoding,
          countryMessages: messages,
        });
        // Refresh view with updated values
        this.defaultMessage = '';
        this.countryMessages = [];
        this.getTextTemplate(this.templateId);
      } else {
        // create new
        this.snackbarText = this.$t('textTemplate.savedSuccessfully');
        let result = await this.$store.dispatch('textTemplate/createTextTemplate', {
          name: this.name,
          defaultMessage: this.defaultMessage,
          defaultMessageEncoding: this.defaultMessageEncoding,
          countryMessages: messages,
        });
        if (result.id) {
          (this.defaultMessage = ''), (this.countryMessages = []);
          this.$router.push({
            name: 'TextTemplate',
            params: { id: result.id },
          });
        }
      }
      this.saving = false;
      this.snackbar = true;
      // this.$router.push({ name: 'TextTemplates' });
    },
    async deleteTemplate() {
      if (this.templateId) {
        this.$refs.confirm
          .open(
            this.$t('textTemplate.deleteHeading'),
            this.$t('textTemplate.deleteMsg'),
            this.$t('ok'),
            this.$t('cancel'),
            { color: 'third', width: '400px' },
          )
          .then((confirm) => {
            if (confirm) {
              this.saving = true;
              // Update existing template
              this.$store
                .dispatch('textTemplate/deleteTextTemplate', {
                  id: this.templateId,
                })
                .then(() => {
                  this.saving = false;
                  this.$router.push({ name: 'TextTemplates' });
                })
                .catch((error) => {
                  this.saving = false;
                  alert(error);
                });
            }
          });
      }
    },
    navigateBack() {
      this.$router.push({ name: 'TextTemplates' });
    },
  },
};
</script>
<style lang="scss" scoped>
.user-info {
  background-color: #fff5da;
  border-radius: 6px;
  p {
    margin: 0;
  }
}
.country-msg-card {
  padding: 8px 0px;
  margin: 0px 0px;
}
</style>
