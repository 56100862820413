<template>
  <div class="content">
    <h3 v-if="text" class="ma-8">{{ text }}</h3>
    <lottie-animation
      ref="anim"
      class="animation"
      :animation-data="require('@/assets/animations/110-location.json')"
      :loop="true"
      :auto-play="true"
    />
    <!-- <v-progress-circular

      indeterminate
      :size="80"
      :width="7"
      color="primary"
    /> -->
  </div>
</template>
<script>
import LottieAnimation from 'lottie-web-vue';
export default {
  name: 'Loading',
  components: {
    LottieAnimation,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  font-size: 2em;
  text-align: center;
}
.animation {
  max-width: 300px;
  text-align: center;
  margin: auto;
}
</style>
