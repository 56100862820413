export const textIsUcs2 = function (text) {
  let ia5Characters =
    '@£$¥èéùìòÇ\nØø\rÅå' +
    '\u2A02_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039E\\027ÆæßÉ' +
    ' !"#¤%&\'()*+,-./' +
    '0123456789:;<=>?' +
    '\u00a1ABCDEFGHIJKLMNO' +
    'PQRSTUVWXYZÄÖÑÜ§' +
    '¿abcdefghijklmno' +
    'pqrstuvwxyzäöñüà';
  let extendedCharacters = '\u000c{}[]^|\u20AC~\\';

  let chars = text.split('');
  let isUcs2 = false;
  for (let i = 0; i < chars.length; i++) {
    let index = ia5Characters.indexOf(chars[i]);
    if (index === -1) {
      index = extendedCharacters.indexOf(chars[i]);
      if (index === -1) {
        isUcs2 = true;
        break;
      }
    }
  }
  return isUcs2;
};

export const extendedCharactersCount = function (text) {
  let count = 0;
  let extendedCharacters = '\u000c{}[]^|\u20AC~\\';
  let chars = text.split('');
  for (let i = 0; i < chars.length; i++) {
    let index = extendedCharacters.indexOf(chars[i]);
    if (index >= 0) count++;
  }
  return count;
};

export const isValidSmsOriginator = function (text) {
  let allowedLetters = /^[A-Za-z 0-9]+$/;
  if (text.match(allowedLetters)) {
    return true;
  }
  return false;
};
