<template>
  <v-card class="pa-2" :flat="flat">
    <h4 class="py-2 d-flex mb-2">
      <rounded-flag :country="item.country.code" size="35px" class="d-none d-sm-flex" />
      <template v-if="item.count">
        <span class="ml-2 country-name">{{ item.country.name }}&nbsp;-&nbsp;</span>
        <ICountUp class="counter my-2" :delay="delay" :end-val="item.count" :options="options" />
        <span class="ml-2 my-2"> {{ $t('recipients') }}</span>
      </template>
      <template v-else>
        <span class="ml-2 country-name">{{ item.country.name }}</span>
      </template>
      <v-spacer />
      <v-btn icon color="third" aria-label="Fjern" @click="removeMessage">
        <v-icon>fal fa-times-circle</v-icon></v-btn
      >
    </h4>
    <!-- eslint-disable vue/no-mutating-props -->
    <v-textarea
      :key="item.country.code"
      v-model="item.message"
      outlined
      auto-grow
      autofocus
      rows="2"
      row-height="30"
      :label="$t('wizard.message')"
      color="accent"
      hide-details="auto"
      :error="!hasValidMessage"
      @input="remainingCharactersCount"
    />
    <!-- @keyup="$emit('validateMessage', item)" -->
    <!-- eslint-enable vue/no-mutating-props -->
    <p class="mb-0 mt-1 mr-1 caption text-right" :class="!hasValidMessage ? 'error--text' : ''">
      {{ characterCountText }}
    </p>
    <v-alert v-if="exceededCharactersCount" type="error" dense>
      {{ $t('wizard.messageTooLong', { maxLength: messageMaxLength }) }}
    </v-alert>
    <v-alert v-if="item.encoding === 'ucs-2'" dense text type="warning">
      {{ $t('wizard.messageContainsSpecialCharacter') }}
    </v-alert>
  </v-card>
</template>

<script>
// TODO: should probably remove mutation of prop in <template> and <script>
/* eslint-disable vue/no-mutating-props */
import RoundedFlag from '@/components/RoundedFlag.vue';
import { textIsUcs2, extendedCharactersCount } from '@/util/messageHelper';
import ICountUp from 'vue-countup-v2';

export default {
  name: 'CountryMessageInput',
  components: {
    RoundedFlag,
    ICountUp,
  },
  props: {
    item: {
      type: [Object],
      default: null,
    },
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    remainingCharactersInSms: 160,
    delay: 0,
    endVal: 0,
    options: {
      duration: 0,
      useEasing: true,
      useGrouping: true,
      separator: ' ',
      decimal: '.',
      prefix: '',
      suffix: '',
    },
    messageMaxLength: 0,
    messageTextLength: 0,
    countrySmsCount: 0,
  }),
  computed: {
    countryMessages() {
      return this.$store.state.alert.countryMessages;
    },
    hasValidMessage() {
      return this.validateMessage();
    },
    exceededCharactersCount() {
      if (this.messageTextLength > this.messageMaxLength) {
        return true;
      } else {
        return false;
      }
    },
    characterCountText() {
      return (
        this.$t('characters') +
        this.messageTextLength +
        ' / ' +
        this.messageMaxLength +
        ' (' +
        this.countrySmsCount +
        ' SMS)'
      );
    },
  },
  watch: {
    item: function () {
      // watch it
      this.remainingCharactersCount();
      this.$store.dispatch('alert/updateCountryMessage', this.item);
    },
  },
  created() {
    if (this.item.encoding == 'ucs-2') {
      this.messageMaxLength = 420 - 6 * 3;
      this.messageTextLength = this.item.message.length;
    } else {
      this.messageMaxLength = 960 - 6 * 7;
      let extCount = extendedCharactersCount(this.item.message);
      this.messageTextLength = extCount + this.item.message.length;
    }
    if (this.item.message.length > 0) {
      this.remainingCharactersCount();
    }
  },
  methods: {
    getCountryMessage(country) {
      const cm = this.countryMessages.find((c) => c.country.code == country.code);
      return cm ? cm.message : '';
    },
    removeMessage() {
      this.$store.dispatch('alert/removeCountryMessage', this.item);
      this.$emit('removeCountryMessage', this.item);
    },

    remainingCharactersCount() {
      this.$store.dispatch('alert/updateCountryMessage');

      if (!this.item.message || this.item.message.length === 0) {
        this.messageTextLength = 0;
        this.item.encoding = 'gsm-7';
        this.messageMaxLength = 960 - 6 * 7;
        return;
      }
      let isUcs2 = false;
      if (this.item.message.length > 0) {
        isUcs2 = textIsUcs2(this.item.message);
      }

      this.messageTextLength = this.item.message.length;
      if (isUcs2) {
        this.item.encoding = 'ucs-2';
        this.messageMaxLength = 420 - 6 * 3;
      } else {
        this.item.encoding = 'gsm-7';
        this.messageMaxLength = 960 - 6 * 7;
        let extCount = extendedCharactersCount(this.item.message);
        this.messageTextLength = extCount + this.item.message.length;
      }

      this.countrySmsCount = this.numberOfCountryMessageSMS(this.item.encoding);
    },
    numberOfCountryMessageSMS(encoding) {
      let maxSingleSmsLength = 160;
      let internalLength = 153;
      if (encoding == 'ucs-2') {
        maxSingleSmsLength = 70;
        internalLength = 67;
      }

      let numberOfSms = 0;
      if (!this.item.message || this.messageTextLength == 0) {
        return 0;
      } else if (this.messageTextLength <= maxSingleSmsLength) {
        numberOfSms = 1;
      } else {
        numberOfSms = this.messageTextLength / internalLength;
      }
      return Math.ceil(numberOfSms);
    },
    validateMessage() {
      if (this.messageTextLength >= 3 && this.messageTextLength <= this.messageMaxLength) {
        this.item.isValid = true;
        this.$emit('validatedMessage', this.item);
        return true;
      } else {
        this.item.isValid = false;
        this.$emit('validatedMessage', this.item);
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flag {
  position: absolute;
  right: 24px;
}
.country-name {
  margin-top: 7px;
}
.fault-card {
  background-color: red;
}
</style>
